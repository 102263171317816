// import logoImg from "/images/logos/logoImg.svg";
// import logoTextImg from "/images/logos/logoText.svg";
import Image from "next/image";
import logo from "@/public/images/logo.svg";
import MyLink from "@/components/shared/myLink/MyLink";

function Logo() {
  return (
    <div
      className={`sticky top-0 start-0 flex gap-2 w-full py-1 ps-1 border-b h-16 cursor-pointer bg-white`}
    >
      {/* <img className="block h-full" src={logoImg} alt="Akhaween logo" /> */}
      {/* <img className="block h-full" src={logoTextImg} alt="Akhaween logo" /> */}
      <MyLink href={"/dashboard"}>
        <Image
          src={logo}
          alt="logo"
        />
      </MyLink>
    </div>
  );
}

export default Logo;
