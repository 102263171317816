import Image from "next/image";
import { SidebarLinkItem } from "../../SidebarLink";
import { ChevronDown } from "lucide-react";

export const SidebarItem = ({
  parent,
  unitStatus,
  arrow = false,
}: {
  parent: SidebarLinkItem;
  unitStatus?: boolean;
  arrow?: boolean;
}) => {
  return (
    <div
      className={`flex w-full items-center justify-between gap-2 p-[4px] capitalize text-[#A1A5B7] font-[500] ${
        unitStatus ? "bg-primary rounded-md text-white" : ""
      }`}
    >
      <div className="flex items-center gap-2 p-[4px]">
        {parent?.icon && (
          <parent.icon
            size={32}
            className={`shrink-0 ms-4 p-1 ${unitStatus ? "fill-white" : ""}`}
          />
        )}
        {parent?.image && (
          <Image
            src={
              unitStatus && parent?.imageFill
                ? parent?.imageFill
                : parent?.image
            }
            alt={parent?.label}
            width={32}
            height={32}
            className={`shrink-0 ms-4 p-1`}
          />
        )}
        <p className="font-[500] truncate">{parent?.label}</p>
      </div>

      {arrow && (
        <div
          className={`w-fit h-fit transform transition-transform duration-300 ${
            unitStatus ? "rotate-180" : ""
          }`}
        >
          <ChevronDown />
        </div>
      )}
    </div>
  );
};
