"use client";
import Links from "./units/Links";
import Logo from "./units/Logo";
import useSidebar from "./useSidebar";

function Sidebar() {
  const { sidebarLinks } = useSidebar();
  // md:w-16 md:hover:w-[265px]
  return (
    <div
      className={`sticky top-0 shrink-0 border-e-2 h-[100dvh] overflow-y-auto transition-width duration-300 ease-in-out bg-background overflow-x-hidden w-[265px]`}
    >
      <Logo />
      <div className="p-3 w-full">
        {sidebarLinks?.map((el, index) => (
          <div key={index} className=" w-full">
            <p className="text-[#141522] font-[600] pt-3 pb-2 px-1">
              {el.label}
            </p>
            {el?.data?.map((nel) => (
              <Links key={index} parent={nel} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
