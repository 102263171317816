"use client";
import { useState, useRef, useEffect, MutableRefObject } from "react";

interface UseContentCollapseReturn {
  unitStatus: boolean;
  setUnitStatus: React.Dispatch<React.SetStateAction<boolean>>;
  openHeight: string;
  Element: MutableRefObject<HTMLDivElement | null>;
  handleCollapse: () => void;
}

function useContentCollapse(
  gap: number = 0,
  depndency: any[] = []
): UseContentCollapseReturn {
  const [unitStatus, setUnitStatus] = useState<boolean>(false);
  const [openHeight, setOpenHeight] = useState<string>("0px");
  const Element = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (Element.current && Element.current.children) {
      const sumHeight = Array.from(Element.current.children).reduce(
        (acc, el) => {
          return acc + (el as HTMLElement).scrollHeight + gap;
        },
        0
      );
      setOpenHeight(`${sumHeight}px`);
    }
  }, [gap, unitStatus, ...depndency]);

  const handleCollapse = () => {
    setUnitStatus(!unitStatus);
  };

  return { unitStatus, setUnitStatus, openHeight, Element, handleCollapse };
}

export default useContentCollapse;
