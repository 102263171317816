import { SidebarItem } from "./SidebarItem";
import { useEffect } from "react";
import MyLink from "@/components/shared/myLink/MyLink";
import { usePathname } from "next/navigation";
import { SidebarLinkItem } from "../../SidebarLink";
import useContentCollapse from "@/sharedHooks/useContentCollapse";
import getPurePathName from "@/utils/lang/getPurePathName";

function Links({ parent }: { parent: SidebarLinkItem }) {
  const pathname = usePathname();

  const { unitStatus, setUnitStatus, openHeight, Element, handleCollapse } =
    useContentCollapse();

  const pathnamePure = getPurePathName(pathname);
  // console.log("pathnamePure", pathnamePure);
  useEffect(() => {
    parent.children?.some(
      (el) => el.type === "link" && pathnamePure === el.href
    ) && setUnitStatus(true);
  }, []);
  return (
    <div className="flex  w-full  flex-col gap-0 text-[#A1A5B7] font-[500]">
      {parent.type === "link" && (
        <MyLink
          className={`flex  w-full h-[36px] items-center gap-2 capitalize ${
            pathnamePure === parent.href
              ? "bg-[#f4f4f4] text-[#141522] hover:bg-[#f4f4f4]"
              : "hover:bg-[#f4f4f4]/30"
          }`}
          href={parent.href || "#"}
        >
          <SidebarItem parent={parent} />
        </MyLink>
      )}
      {parent.type === "toggle" && (
        <div
          className={`flex w-full items-center gap-2 capitalize cursor-pointer ${
            parent.activeName && pathname.includes(parent?.activeName as string)
              ? "bg-[#f4f4f4] hover:bg-[#f4f4f4]"
              : "hover:bg-[#f4f4f4]/30"
          } `}
          onClick={handleCollapse}
        >
          <SidebarItem unitStatus={unitStatus} parent={parent} arrow={true} />
        </div>
      )}

      <div
        ref={Element}
        style={{ height: unitStatus ? openHeight : "0" }}
        className="transition-[height]  w-full duration-300 overflow-hidden "
      >
        {parent.children?.map((el) => {
          // console.log("pathnamewww", pathname);
          // console.log("el?.activeName", el?.activeName);

          return (
            <MyLink
              key={el.id}
              className={`flex  w-full h-[36px] items-center gap-2 py-2 capitalize ps-5 ${
                el.activeName && pathname.includes(el?.activeName as string)
                  ? "bg-[#f4f4f4] text-[#141522] hover:bg-[#f4f4f4]"
                  : "hover:bg-[#f4f4f4]/30"
              }`}
              href={el.href || "#"}
            >
              {/* <div className="rounded-full w-2 h-2 bg-primary" /> */}
              <p className="text-start w-full text-[#a1a5b7] truncate">
                {el.label}
              </p>
            </MyLink>
          );
        })}
      </div>
    </div>
  );
}

export default Links;
