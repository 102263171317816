import { BarChart3, Archive, ClipboardList, ListChecks } from "lucide-react";
import { SidebarLinkGroup } from "../SidebarLink";
import { useTranslate } from "@/components/base/providers/TranslateProv";
import MainIcon from "@/public/icons/main.svg";
import UsersIcon from "@/public/icons/users.svg";
import UniversityIcon from "@/public/icons/university.svg";
import MainIconFill from "@/public/icons/mainFill.svg";
import UsersIconFill from "@/public/icons/usersFill.svg";
import UniversityIconFill from "@/public/icons/universityFill.svg";
import ConsultationsFill from "@/public/icons/ConsultationsFill.svg";
import ServicesIconsFill from "../../../../../../public/icons/servicesFill.svg";
import ServicesIcon from "@/public/icons/servicesIcon.svg";
import OrdeermanagementIconFill from "@/public/icons/order-managementFill.svg";
import adsIcon from "@/public/icons/ads.svg";
import ArticleIcon from "@/public/icons/ArticleIcon.svg";
import ArticleFillIcon from "@/public/icons/ArticleFillIcon.svg";
import contactUsIcon from "@/public/icons/contactUs.svg";
import SiteManagement from "@/public/icons/SiteManagementIcon.svg";
const useSidebar = () => {
  const { t } = useTranslate("sidebar");

  const sidebarLinks: SidebarLinkGroup[] = [
    {
      label: t?.systemInfo,
      data: [
        {
          id: "link-1",
          label: t?.main,
          href: "/",
          image: MainIcon,
          imageFill: MainIconFill,
          type: "link",
        },
        {
          id: "link-2",
          label: t?.users,
          href: "/requests",
          image: UsersIcon,
          imageFill: UsersIconFill,
          type: "link",
        },
        {
          id: "link-3",
          label: t?.university,
          image: UniversityIcon,
          imageFill: UniversityIconFill,
          type: "toggle",
          children: [
            {
              id: "child-link-1",
              label: t?.universityExists,
              href: "/dashboard/university",
              activeName: "university",
              type: "link",
            },
            {
              id: "child-link-2",
              label: t?.facultyExists,
              href: "/dashboard/faculty",
              activeName: "faculty",
              type: "link",
            },
            {
              id: "child-link-3",
              label: t?.faculitiesList,
              href: "/dashboard/faculitiesList",
              activeName: "faculitiesList",
              type: "link",
            },
            {
              id: "child-link-4",
              label: t?.specialization,
              href: "/dashboard/specialization",
              activeName: "specialization",
              type: "link",
            },
          ],
        },
        {
          id: "link-4",
          label: t?.Consultations,
          href: "/dashboard/consultations",
          image: ConsultationsFill,
          imageFill: ConsultationsFill,
          type: "link",
        },
        {
          id: "link-5",
          label: t?.Services,
          href: "/dashboard/services",
          image: ServicesIcon,
          imageFill: ServicesIconsFill,
          type: "link",
        },
        {
          id: "link-6",
          label: t?.OrderManagement,
          image: OrdeermanagementIconFill,
          imageFill: OrdeermanagementIconFill,
          type: "toggle",
          children: [
            {
              id: "child-link-21",
              label: t?.PaidConsultation,
              href: "/dashboard/paid-consultation",
              activeName: "paid-consultation",
              type: "link",
            },
            {
              id: "child-link-31",
              label: t?.FreeConsultation,
              href: "/dashboard/free-consultation",
              activeName: "free-consultation",
              type: "link",
            },
            {
              id: "child-link-41",
              label: t?.OtherServicesProvided,
              href: "/dashboard/other-services-provided",
              activeName: "other-services-provided",
              type: "link",
            },
            {
              id: "child-link-51",
              label: t?.doctors,
              href: "/dashboard/doctors",
              activeName: "Doctors",
              type: "link",
            },
            {
              id: "child-link-51",
              label: t?.Courses,
              href: "/dashboard/courses",
              activeName: "courses",
              type: "link",
            },
          ],
        },
        {
          id: "link-7",
          label: t?.ads,
          href: "/dashboard/ads",
          image: adsIcon,
          imageFill: adsIcon,
          type: "link",
        },
      ],
    },
    {
      label: "اساسية",
      data: [
        // {
        //   id: "link-1",
        //   label: "sssss",
        //   href: "/",
        //   icon: BarChart3,
        //   type: "link",
        // },
        {
          id: "link-2",
          label: t?.contact,
          href: "/dashboard/contact-us",
          image: contactUsIcon,
          imageFill: contactUsIcon,
          type: "link",
        },
        // {
        //   id: "link-3",
        //   label: "wwwwww",
        //   href: "/services_products",
        //   icon: ListChecks,
        //   type: "link",
        // },
      ],
    },
    {
      label: t?.ImportantSettings,
      data: [
        {
          id: "link-1",
          label: t?.Article,
          href: "/dashboard/article",
          image: ArticleIcon,
          imageFill: ArticleFillIcon,
          type: "link",
        },
        {
          id: "link-2",
          label: t?.SiteManagement,
          href: "/dashboard/site_management",
          image: SiteManagement,
          imageFill: SiteManagement,
          // icon: SiteManagement,
          type: "link",
        },
        //   {
        //     id: "link-3",
        //     label: "wwwwww",
        //     href: "/services_products",
        //     icon: ListChecks,
        //     type: "link",
        //   },
        //   {
        //     id: "link-4",
        //     label: "tttt",
        //     icon: Archive,
        //     type: "toggle",
        //     children: [
        //       {
        //         id: "child-link-1",
        //         label: "",
        //         href: "/requests_archives",
        //         type: "link",
        //       },
        //       {
        //         id: "child-link-2",
        //         label: "",
        //         href: "/services_products_archives",
        //         type: "link",
        //       },
        //       {
        //         id: "child-link-3",
        //         label: "",
        //         href: "/alerts_archives",
        //         type: "link",
        //       },
        //       {
        //         id: "child-link-4",
        //         label: "",
        //         href: "/bills_archives",
        //         type: "link",
        //       },
        //     ],
        //   },
        // ],
      ],
    },
  ];

  return { sidebarLinks };
};

export default useSidebar;
